"use client";
import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Image from "next/image";
import errorImg from "../public/svg/errorImg.svg";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { useRouter } from "next/navigation";

export default function NotFound() {
    const router = useRouter();
    return (
        <Box
            sx={{
                bgcolor: "#3EAA6D1A",
                width: "100vw",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden", // To hide potential overflow
            }}
        >
            <CssBaseline />
            <Container>
                <Grid
                    container
                    spacing={2}
                    flexDirection={{
                        xs: "row-reverse",
                        sm: "row-reverse",
                        md: "row-reverse",
                        lg: "row",
                    }}
                    xs={{ flexDirection: "column" }}
                    alignItems="center"
                    height="100%"
                >
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        display="flex"
                        justifyContent="center"
                    >
                        <Box display="inline-block">
                            <Box display="flex" flexDirection="column">
                                <Typography
                                    variant="h4"
                                    sx={{
                                        WebkitBackgroundClip: "text",
                                        WebkitTextFillColor: "transparent",
                                        backgroundImage:
                                            "linear-gradient(to right, #053C61, #053C61)",
                                        gap: "5px",
                                    }}
                                >
                                    Opps!!
                                </Typography>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        WebkitBackgroundClip: "text",
                                        WebkitTextFillColor: "transparent",
                                        fontWeight: "bold",
                                        backgroundImage:
                                            "linear-gradient(to right, #053C61, #053C61)",
                                        gap: "5px",
                                    }}
                                >
                                    Page Not Found
                                </Typography>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        padding: "15px 0px",
                                        WebkitBackgroundClip: "text",
                                        WebkitTextFillColor: "transparent",
                                        backgroundImage:
                                            "linear-gradient(to right, #053C61, #053C61)",
                                    }}
                                >
                                    We'll get back to you
                                </Typography>
                                <Button
                                    onClick={() => {
                                        router.push("/");
                                    }}
                                    sx={{
                                        marginTop: "15px",
                                        padding: "15px",
                                        width: "150px",
                                        borderRadius: "30px",
                                        background:
                                            "linear-gradient(to right, #053C61, #053C61)",
                                        color: "white",
                                    }}
                                >
                                    Go Back
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        textAlign={{ xs: "center", lg: "left" }}
                    >
                        <Image src={errorImg} height={100} width={100} alt="error Image" />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
